// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/common/NavLink.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/common/NavLink.tsx");
  import.meta.hot.lastModified = "1708984159613.9106";
}
// REMIX HMR END

import { Link, useLocation } from '@remix-run/react';
import React from 'react';
import classNames from 'classnames';
export default function NavLink({
  to,
  children
}) {
  _s();
  const location = useLocation();
  return <div>
      <Link to={to} className={classNames('text-xl font-medium hover:text-primary-500', {
      'text-primary-500': location.pathname === to,
      'text-white': location.pathname !== to
    })}>
        {children}
      </Link>
    </div>;
}
_s(NavLink, "pkHmaVRPskBaU4tMJuJJpV42k1I=", false, function () {
  return [useLocation];
});
_c = NavLink;
var _c;
$RefreshReg$(_c, "NavLink");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;