// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Scripts, ScrollRestoration } from '@remix-run/react';
import stylesheet from './tailwind.css';
import { ToastContainer } from 'react-toastify';
import toastify from 'react-toastify/dist/ReactToastify.css';
import React, { Suspense } from 'react';
import Layout from './components/layout/Layout';
import { authenticator } from './services/auth.server';
import { typedjson, useTypedLoaderData } from 'remix-typedjson';
import { PageProvider } from './context/page.context';
export const meta = () => [{
  charset: 'utf-8',
  title: 'NodeAI Admin',
  viewport: 'width=device-width,initial-scale=1'
}];
export const links = () => [{
  rel: 'stylesheet',
  href: stylesheet
}, {
  rel: 'stylesheet',
  href: toastify
}, {
  rel: 'icon',
  href: '/favicon.png'
}];
export const loader = async ({
  request
}) => {
  const url = new URL(request.url);
  if (url.pathname === '/login') {
    return typedjson({
      user: null
    });
  }
  const user = await authenticator.isAuthenticated(request, {
    failureRedirect: '/login'
  });
  return typedjson({
    user
  });
};
export default function App() {
  _s();
  const {
    user
  } = useTypedLoaderData();
  return <html lang="en">
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name={'icon'} content={'/favicon.png'} />
      <Meta />
      <Links />
    </head>
    <body>

    <PageProvider user={user}>
      <Layout />
    </PageProvider>

    <Suspense>
      <ToastContainer position={'top-right'} toastClassName={() => 'bg-dark-500 relative flex flex-row p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'} bodyClassName={() => 'flex flex-row gap-2 text-sm font-white block p-3'} />


    </Suspense>
    <ScrollRestoration />
    <Scripts />
    <LiveReload />
    </body>
    </html>;
}
_s(App, "BtoCd6MxSNyQg8SJ5awbD6wNDwA=", false, function () {
  return [useTypedLoaderData];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;