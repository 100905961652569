// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layout/Navbar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layout/Navbar.tsx");
  import.meta.hot.lastModified = "1708984159613.9106";
}
// REMIX HMR END

import NavLink from '../common/NavLink';
import Logo from '../../images/logo.png';
import usePageContext from '../../context/page.context';
export default function Navbar() {
  _s();
  const {
    user
  } = usePageContext();
  return <div className={'p-4 w-full flex flex-col xl:flex-row gap-4 text-white'}>
      <div className={'h-24 w-auto flex-shrink-0'}>
        <img src={Logo} alt={'logo'} className={'w-full h-full object-contain'} />
      </div>
      <div className={'flex flex-wrap flex-grow justify-center xl:justify-end items-center gap-10 xl:gap-24'}>
        {user && <>
            <NavLink to={'/'}>Overview</NavLink>
            <NavLink to={'/nodes'}>Nodes</NavLink>
            <NavLink to={'/logout'}>Logout</NavLink>
          </>}
        {!user && <>
            <NavLink to={'/login'}>Login</NavLink>
          </>}
      </div>
    </div>;
}
_s(Navbar, "veJhTeVV7XGuMf0Y2JSrl4GksfU=", false, function () {
  return [usePageContext];
});
_c = Navbar;
var _c;
$RefreshReg$(_c, "Navbar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;