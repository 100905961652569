// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layout/Layout.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layout/Layout.tsx");
  import.meta.hot.lastModified = "1708984159613.9106";
}
// REMIX HMR END

import { Outlet } from '@remix-run/react';
import Navbar from './Navbar';
export default function Layout() {
  return <div className={'bg-dark-500 w-screen overflow-x-hidden flex justify-center custom-gradient'}>
      <div className={'flex flex-col container min-h-screen'}>
        <Navbar />
        <div className={'flex flex-grow flex-col my-4 p-4 xl:p-12 rounded-xl bg-dark-400 bg-opacity-80 relative'}>
          <Outlet />
        </div>
      </div>
    </div>;
}
_c = Layout;
var _c;
$RefreshReg$(_c, "Layout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;