// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/context/page.context.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/context/page.context.tsx");
  import.meta.hot.lastModified = "1708984159613.9106";
}
// REMIX HMR END

import { createContext, useContext } from 'react';
const PageContext = createContext({});
export function PageProvider({
  user,
  children
}) {
  return <PageContext.Provider value={{
    user
  }}>
      {children}
    </PageContext.Provider>;
}
_c = PageProvider;
export default function usePageContext() {
  _s();
  return useContext(PageContext);
}
_s(usePageContext, "gDsCjeeItUuvgOWf1v4qoK9RF6k=");
var _c;
$RefreshReg$(_c, "PageProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;